import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_SHADE_PICKER } from '@estee/elc-service-view-names';
import { IShadePicker } from '~interfaces/IShadePicker';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IShadePicker>(
    () => import('./ProductShadePicker'),
    PRODUCT_SHADE_PICKER,
    serviceNames.productShadePickerViewController
);

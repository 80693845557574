import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import { events, on, queryProvider, serviceQueries } from '@estee/elc-service-bus';

const queries = [
    [serviceQueries.GET_PRODUCT_DATA, 'getProductData'],
    [serviceQueries.GET_PRODUCTS, 'getProductSkus'],
    [serviceQueries.GET_PRODUCTS_V2, 'getProductsV2'],
    [serviceQueries.GET_PRODUCTS_V3, 'getProductsV3'],
    // @todo remove on MPPFIND-4454
    [serviceQueries.PAGE_VIEW_TRACK_PRODUCT_GRID, 'getAnalyticsDataForProductGrid'],
    // @todo remove on MPPFIND-4454
    [serviceQueries.PAGE_VIEW_TRACK_PRODUCT_FULL, 'getAnalyticsDataForProductFull'],
    [serviceQueries.GET_SKUS, 'getSkus'],
    [serviceQueries.GET_REVIEW_PRODUCT, 'getReviewProduct'],
    [serviceQueries.GET_SORTBY_VALUE, 'getSortbyValue']
];

const onEvents = [[events.NAVIGATE, 'productRouting']];
class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        const id = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (serviceBusBaseClass) {
                clearInterval(id);
                queries.forEach(ServiceManager.setDecoratorOnFunction(queryProvider));
                onEvents.forEach(ServiceManager.setDecoratorOnFunction(on));
            }
        }, 50);
    };
}

export const serviceBusBaseClass: ServiceBusController & ServiceBusControllerBody =
    new ServiceBus();

import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_BASIC_INFO } from '@estee/elc-service-view-names';
import { IProductBasicInfo } from './ProductBasicInfo';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductBasicInfo>(
    () => import('./ProductBasicInfo'),
    PRODUCT_BASIC_INFO,
    serviceNames.productBasicInfoViewController
);

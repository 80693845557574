import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class ProductService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }
}

export default {
    pubSub: 'PUB_SUB_CLIENT',
    prodcatApiSdk: 'PRODCAT_API_SDK',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    productService: 'PRODUCT_SERVICE',
    productSkuRepository: 'PRODUCT_SKU_REPOSITORY',
    productCTAViewController: 'PRODUCT_CTA_VIEW_CONTROLLER',
    productFullViewController: 'PRODUCT_FULL_VIEW_CONTROLLER',
    hoverZoom: 'HOVER_ZOOM',
    zoomViewBaseController: 'ZOOM_VIEW_BASE_CONTROLLER',
    productHoverZoomItemViewController: 'PRODUCT_HOVER_ZOOM_ITEM_VIEW_CONTROLLER',
    productZoomButtonViewController: 'PRODUCT_ZOOM_BUTTON_VIEW_CONTROLLER',
    productHoverZoomButtonViewController: 'PRODUCT_HOVER_ZOOM_BUTTON_VIEW_CONTROLLER',
    productBriefViewController: 'PRODUCT_BRIEF_VIEW_CONTROLLER',
    productGridViewController: 'PRODUCT_GRID_VIEW_CONTROLLER',
    productRepository: 'PRODUCT_REPOSITORY',
    inventoryRepository: 'INVENTORY_REPOSITORY',
    productCrossSellViewController: 'PRODUCT_CROSS_SELL_VIEW_CONTROLLER',
    cartLimitReachedModalController: 'CART_LIMIT_REACHED_MODAL_CONTROLLER',
    productQuickViewController: 'PRODUCT_QUICK_VIEW_CONTROLLER',
    productFiltersViewController: 'PRODUCT_FILTERS_VIEW_CONTROLLER',
    productShadesSidebarViewController: 'PRODUCT_SHADES_SIDEBAR_VIEW_CONTROLLER',
    productZoomViewController: 'PRODUCT_ZOOM_VIEW_CONTROLLER',
    productHoverZoomViewController: 'PRODUCT_HOVER_ZOOM_VIEW_CONTROLLER',
    filterService: 'FILTER_SERVICE',
    businessUnitIdParser: 'BUSINESS_UNIT_ID_PARSER',
    config: 'CONFIG',
    configRepository: 'CONFIG_REPOSITORY',
    productGridSettings: 'MPP_SETTINGS',
    productSelectionRepository: 'PRODUCT_SELECTION_REPOSITORY',
    queryFragments: 'QUERY_FRAGMENTS',
    dateFormatter: 'DATE_FORMATTER',
    priceFormatter: 'PRICE_FORMATTER',
    productInstallmentsPriceViewController: 'PRODUCT_INSTALLMENTS_PRICE_VIEW_CONTROLLER',
    productEngravingViewController: 'PRODUCT_ENGRAVING_VIEW_CONTROLLER',
    productFullService: 'PRODUCT_FULL_SERVICE',
    productGridService: 'PRODUCT_GRID_SERVICE',
    productSortingService: 'PRODUCT_SORTING_SERVICE',
    productShadePickerViewController: 'PRODUCT_SHADE_PICKER_VIEW_CONTROLLER',
    productShadePickerComponentViewController: 'PRODUCT_SHADE_PICKER_CONMPONENT_VIEW_CONTROLLER',
    productBasicInfoViewController: 'PRODUCT_BASIC_INFO_VIEW_CONTROLLER',
    productImagesSliderViewController: 'PRODUCT_IMAGES_SLIDER_VIEW_CONTROLLER',
    productSizePickerViewController: 'PRODUCT_SIZE_PICKER_VIEW_CONTROLLER',
    comparisonItemViewController: 'COMPARISON_ITEM_VIEW_CONTROLLER',
    productComparisonModuleViewController: 'PRODUCT_COMPARISON_MODULE_VIEW_CONTROLLER',
    urlFormatter: 'URL_FORMATTER',
    stickyContainerFeatureViewController: 'STICKY_CONTAINER_FEATURE_VIEW_CONTROLLER',
    skuPickerViewController: 'SKU_PICKER_VIEW_CONTROLLER',
    shadeFiltersViewController: 'SHADE_FILTERS_VIEW_CONTROLLER'
};

import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_BRIEF } from '@estee/elc-service-view-names';
import { IProductBrief } from '~interfaces/IProductBrief';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductBrief>(
    () => import('./ProductBrief'),
    PRODUCT_BRIEF,
    serviceNames.productBriefViewController
);

import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_COMPARISON_MODULE } from '@estee/elc-service-view-names';
import { IProductComparisonModule } from './ProductComparisonModule';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductComparisonModule>(
    () => import('./ProductComparisonModule'),
    PRODUCT_COMPARISON_MODULE,
    serviceNames.productComparisonModuleViewController
);

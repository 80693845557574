import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_CTA } from '@estee/elc-service-view-names';
import { IProductCTA } from '~interfaces/IProductCTA';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductCTA>(
    () => import('./ProductCTA'),
    PRODUCT_CTA,
    serviceNames.productCTAViewController
);

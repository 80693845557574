import { setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import serviceNames, { ProductService } from '~setup/ProductService';
import {
    PRODUCT_CTA,
    PRODUCT_FULL,
    PRODUCT_GRID,
    PRODUCT_CROSS_SELL,
    PRODUCT_MEDIA_CAROUSEL_NAV,
    PRODUCT_QUANTITY_SELECTOR,
    PRODUCT_SIZE_PICKER,
    PRODUCT_SHADE_PICKER,
    PRODUCT_SHADE_PICKER_BUTTON,
    PRODUCT_BASIC_INFO,
    PRODUCT_COMPARISON_MODULE,
    PRODUCT_BRIEF
} from '@estee/elc-service-view-names';
import { serviceBusBaseClass } from './service-setup/ServiceBus';

import './exported/product-basic-info/index';
import './exported/product-brief/index';
import './exported/product-comparison-module/index';
import './exported/product-cross-sell/index';
import './exported/product-cta/index';
import './exported/product-full/index';
import './exported/product-grid-wrapper/index';
import './exported/product-image-nav-menu/index';
import './exported/product-quantity-selector/index';
import './exported/product-shade-picker/index';
import './exported/product-shade-picker-button/index';
import './exported/product-size-picker-wrapper/index';

setupServiceInfo(__serviceInfo__);

const { name, version } = __serviceInfo__;
// @ts-ignore
ProductService.setViews(name, name.replace(/-/g, '_'), version, [
    PRODUCT_CTA,
    PRODUCT_BRIEF,
    PRODUCT_FULL,
    PRODUCT_GRID,
    PRODUCT_CROSS_SELL,
    PRODUCT_MEDIA_CAROUSEL_NAV,
    PRODUCT_QUANTITY_SELECTOR,
    PRODUCT_SIZE_PICKER,
    PRODUCT_SHADE_PICKER,
    PRODUCT_SHADE_PICKER_BUTTON,
    PRODUCT_BASIC_INFO,
    PRODUCT_COMPARISON_MODULE
]);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.productService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBus: { serviceBusBaseClass }
});

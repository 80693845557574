import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_CROSS_SELL } from '@estee/elc-service-view-names';
import { IProductCrossSell } from './ProductCrossSell';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductCrossSell>(
    () => import('./ProductCrossSell'),
    PRODUCT_CROSS_SELL,
    serviceNames.productCrossSellViewController
);

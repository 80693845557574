import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_GRID } from '@estee/elc-service-view-names';
import { IProductGridWrapper } from './ProductGridWrapper';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductGridWrapper>(
    () => import('./ProductGridWrapper'),
    PRODUCT_GRID,
    serviceNames.productGridViewController
);

import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_FULL } from '@estee/elc-service-view-names';
import { IProductFull } from '~interfaces/IProductFull';
import serviceNames from '~setup/ProductService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductFull>(
    () => import('./ProductFull'),
    PRODUCT_FULL,
    serviceNames.productFullViewController
);
